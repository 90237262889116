@import './../../../../styles/colors';

.devices-filter-menu-component {
    padding: 6px;
    border-bottom: 3px solid $midnight4;
    .filter-form {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-evenly;
    }
}