@import "../../../../styles/colors";

.export-dialog-component {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: 16px;
  background-color: $overlay;

  .dialog-header {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

    .btn-wrapper {
      .btn-close-dialog {
        border-radius: 100px;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .dialog-content {
    position: relative;

    background-color: $midnight4;
    border-radius: 6px;

    margin-top: 16px;
    padding: 16px;
    text-align: center;

    .title {
      font-size: 20px;
      line-height: 40px;
    }
    .message {
      display: inline-block;
      text-transform: lowercase;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
    }
    .help-text {
      line-height: 40px;
    }
    .icon {
      position: relative;
      top: 3px;
      height: 20px;
      width: 20px;
      padding: 0;
      padding-left: 6px;
      margin: 0;
      svg {
        path {
          fill: $gulf;
        }
      }
    }
    .edit {
      .edit-input {
        display: inline-block;
      }
      .icon {
        top: 0;
      }
    }

    .error {
      .title {
        color: $red;
      }
      .message {
        color: $red;
      }
    }

    a {
      position: relative;
      display: inline-block;
    }
  }
}
