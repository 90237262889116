@import '../../styles/colors';

.text-input-component {
    margin-top: 4px;
    .label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        &.disabled {
            color: $dusk40;
        }
    }
    input {
        margin-top: 4px;
        margin-bottom: 4px;
        &.invalid {
            border: 1px solid $red;
        }
    }
    .validation-error {
        color: $red;
        text-transform: lowercase;
        font-weight: 800;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 4px;
    }
}