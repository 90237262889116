@import '../../styles/colors';

.single-select-input-component {
    margin-top: 4px;
    .label {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        &.disabled {
            color: $dusk40;
        }
    }
    select {
        margin-top: 4px;
    }
}