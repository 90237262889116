@import "./styles/colors";
@import "./styles/breakpoints";

body {
  background-color: $background-blue;
  margin: 0;
  padding: 0;

  &.no-scroll {
    overflow: hidden;
  }
}

.App {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  max-width: $applicationMaxWidth;
  margin: auto;
}

a {
  color: $gulf;
  text-decoration: none;
  &:visited {
    color: $gulf;
    text-decoration: none;
  }

  &.btn {
    border-radius: 4px;
    margin: 16px 0;
    padding: 8px 24px;
    font-weight: 800;
    font-size: 13px;
    line-height: 24px;
  }

  // Blue background
  &.blue {
    color: $midnight4;
    background-color: $gulf;
    &:disabled {
      background-color: $dusk40;
    }
  }
  // Green background
  &.green {
    color: $midnight4;
    background-color: $green;
    &:disabled {
      background-color: $dusk40;
    }
  }
  // White background
  &.white {
    color: $gulf;
    background-color: $midnight4;
    &:disabled {
      color: $dusk40;
    }
  }
  // No background with border
  &.outline {
    border: 1px solid $gulf;
    background-color: transparent;
    &:disabled {
      color: $dusk40;
      border-color: $dusk40;
    }
  }
}

input,
select {
  margin: 11px 0;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid $midnight10;
  &:disabled {
    color: $dusk40;
    border: 1px solid $disabled-grey;
  }
}

button {
  margin: 16px 0;
  border-style: none;
  border-radius: 4px;
  box-shadow: none;

  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  font-weight: 800;
  font-size: 13px;
  line-height: 24px;

  // default colors (same as 'blue' class)
  color: $midnight4;
  background-color: $gulf;
  &:disabled {
    background-color: $dusk40;
  }

  &:hover {
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }

  &.mini {
    margin: 3px 0;
    padding: 0 6px;
  }

  // Blue background
  &.blue {
    color: $midnight4;
    background-color: $gulf;
    &:disabled {
      background-color: $dusk40;
    }
  }

  // White background
  &.white {
    color: $gulf;
    background-color: $midnight4;
    &:disabled {
      color: $dusk40;
    }
  }
  // No background or border
  &.transparent {
    color: $gulf;
    background-color: transparent;
    &:disabled {
      color: $dusk40;
    }
  }
  // No background with border
  &.outline {
    border: 1px solid $gulf;
    background-color: transparent;
    &:disabled {
      color: $dusk40;
      border-color: $dusk40;
    }
  }
  // Red background
  &.red {
    color: $red;
    background-color: $background-red;
    border: 1px solid $red;
    &:disabled {
      color: $dusk40;
      background-color: $dusk-lightest;
      border: 1px solid $dusk40;
    }
  }
}
