@import "../../../../styles/colors";

$footer-height: 50px;

.devices-data-table-component {
  .device-table-footer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: $midnight4;
    height: $footer-height;
    text-align: center;
    font-size: 13px;
    line-height: $footer-height;

    .center {
      flex: 1;
    }

    .spinner {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: $footer-height;
    }

    .dashboard-error {
      color: $red;
    }

    .export {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 12px;

      button {
        margin: 0;
      }
    }
  }
}
