@import '../../styles/colors';
@import '../../styles/breakpoints';

.flex-table {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .spinner {
    display: block;
    position: relative;
    padding: 12px;
    svg {
      display: block;
      position: relative;
      margin: auto;
    }
  }
}

.flex-table-header-wrapper {
  position: sticky;
  display: block;
  top: 0;
  z-index: 1;
}
.flex-table-data-wrapper {
  position: relative;
  display: block;
}

.flex-table-header.flex-row {
  background-color: $midnight4;
  color: $midnight100;
  font-weight: 800;

  .flex-cell {
    padding: 12px 0;
  }
}

.flex-table-data {
  background-color: transparent;
  border-bottom: 2px solid $midnight4;
  padding: 6px 0;
  &:last-child {
    border-bottom: none;
  }
}

.flex-row {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;

  .flex-cell {
    position: relative;
    flex-grow: 1;
    padding: 6px 0;
    overflow: hidden;
    word-wrap: break-word;

    .mobile-label {
      display: none;
    }

    &.flex-col-width-2 {
      flex-grow: 2;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .flex-table-header {
    display: none;
  }

  .flex-table-data {
    &:nth-child(even) {
      background-color: $midnight4;
    }
  }

  .flex-row {
    flex-direction: column;
    text-align: left;
    padding: 4px 4px 8px 16px;

    .flex-cell {
      width: 100% !important; // override element width, if specified
      padding: 0;

      .mobile-label {
        display: block;
        font-size: 13px;
        line-height: 24px;
        color: $midnight60;

        &.mobile-label-hidden {
          display: none;
        }
      }
    }
  }
}

// hard code widths of cells so flex divs look like a table
// flex-col-N means the div should occupy 1/N of the width
// N to the tenth decimal is supported

.flex-col-0,
.flex-col-1 {
  width: calc(100% / 1);
}
.flex-col-1-1 {
  width: calc(100% / 1.1);
}
.flex-col-1-2 {
  width: calc(100% / 1.2);
}
.flex-col-1-3 {
  width: calc(100% / 1.3);
}
.flex-col-1-4 {
  width: calc(100% / 1.4);
}
.flex-col-1-5 {
  width: calc(100% / 1.5);
}
.flex-col-1-6 {
  width: calc(100% / 1.6);
}
.flex-col-1-7 {
  width: calc(100% / 1.7);
}
.flex-col-1-8 {
  width: calc(100% / 1.8);
}
.flex-col-1-9 {
  width: calc(100% / 1.9);
}

.flex-col-2 {
  width: calc(100% / 2);
}
.flex-col-2-1 {
  width: calc(100% / 2.1);
}
.flex-col-2-2 {
  width: calc(100% / 2.2);
}
.flex-col-2-3 {
  width: calc(100% / 2.3);
}
.flex-col-2-4 {
  width: calc(100% / 2.4);
}
.flex-col-2-5 {
  width: calc(100% / 2.5);
}
.flex-col-2-6 {
  width: calc(100% / 2.6);
}
.flex-col-2-7 {
  width: calc(100% / 2.7);
}
.flex-col-2-8 {
  width: calc(100% / 2.8);
}
.flex-col-2-9 {
  width: calc(100% / 2.9);
}

.flex-col-3 {
  width: calc(100% / 3);
}
.flex-col-3-1 {
  width: calc(100% / 3.1);
}
.flex-col-3-2 {
  width: calc(100% / 3.2);
}
.flex-col-3-3 {
  width: calc(100% / 3.3);
}
.flex-col-3-4 {
  width: calc(100% / 3.4);
}
.flex-col-3-5 {
  width: calc(100% / 3.5);
}
.flex-col-3-6 {
  width: calc(100% / 3.6);
}
.flex-col-3-7 {
  width: calc(100% / 3.7);
}
.flex-col-3-8 {
  width: calc(100% / 3.8);
}
.flex-col-3-9 {
  width: calc(100% / 3.9);
}

.flex-col-4 {
  width: calc(100% / 4);
}
.flex-col-4-1 {
  width: calc(100% / 4.1);
}
.flex-col-4-2 {
  width: calc(100% / 4.2);
}
.flex-col-4-3 {
  width: calc(100% / 4.3);
}
.flex-col-4-4 {
  width: calc(100% / 4.4);
}
.flex-col-4-5 {
  width: calc(100% / 4.5);
}
.flex-col-4-6 {
  width: calc(100% / 4.6);
}
.flex-col-4-7 {
  width: calc(100% / 4.7);
}
.flex-col-4-8 {
  width: calc(100% / 4.8);
}
.flex-col-4-9 {
  width: calc(100% / 4.9);
}

.flex-col-5 {
  width: calc(100% / 5);
}
.flex-col-5-1 {
  width: calc(100% / 5.1);
}
.flex-col-5-2 {
  width: calc(100% / 5.2);
}
.flex-col-5-3 {
  width: calc(100% / 5.3);
}
.flex-col-5-4 {
  width: calc(100% / 5.4);
}
.flex-col-5-5 {
  width: calc(100% / 5.5);
}
.flex-col-5-6 {
  width: calc(100% / 5.6);
}
.flex-col-5-7 {
  width: calc(100% / 5.7);
}
.flex-col-5-8 {
  width: calc(100% / 5.8);
}
.flex-col-5-9 {
  width: calc(100% / 5.9);
}

.flex-col-6 {
  width: calc(100% / 6);
}
.flex-col-6-1 {
  width: calc(100% / 6.1);
}
.flex-col-6-2 {
  width: calc(100% / 6.2);
}
.flex-col-6-3 {
  width: calc(100% / 6.3);
}
.flex-col-6-4 {
  width: calc(100% / 6.4);
}
.flex-col-6-5 {
  width: calc(100% / 6.5);
}
.flex-col-6-6 {
  width: calc(100% / 6.6);
}
.flex-col-6-7 {
  width: calc(100% / 6.7);
}
.flex-col-6-8 {
  width: calc(100% / 6.8);
}
.flex-col-6-9 {
  width: calc(100% / 6.9);
}

.flex-col-7 {
  width: calc(100% / 7);
}
.flex-col-7-1 {
  width: calc(100% / 7.1);
}
.flex-col-7-2 {
  width: calc(100% / 7.2);
}
.flex-col-7-3 {
  width: calc(100% / 7.3);
}
.flex-col-7-4 {
  width: calc(100% / 7.4);
}
.flex-col-7-5 {
  width: calc(100% / 7.5);
}
.flex-col-7-6 {
  width: calc(100% / 7.6);
}
.flex-col-7-7 {
  width: calc(100% / 7.7);
}
.flex-col-7-8 {
  width: calc(100% / 7.8);
}
.flex-col-7-9 {
  width: calc(100% / 7.9);
}

.flex-col-8 {
  width: calc(100% / 8);
}
.flex-col-8-1 {
  width: calc(100% / 8.1);
}
.flex-col-8-2 {
  width: calc(100% / 8.2);
}
.flex-col-8-3 {
  width: calc(100% / 8.3);
}
.flex-col-8-4 {
  width: calc(100% / 8.4);
}
.flex-col-8-5 {
  width: calc(100% / 8.5);
}
.flex-col-8-6 {
  width: calc(100% / 8.6);
}
.flex-col-8-7 {
  width: calc(100% / 8.7);
}
.flex-col-8-8 {
  width: calc(100% / 8.8);
}
.flex-col-8-9 {
  width: calc(100% / 8.9);
}

.flex-col-9 {
  width: calc(100% / 9);
}
.flex-col-9-1 {
  width: calc(100% / 9.1);
}
.flex-col-9-2 {
  width: calc(100% / 9.2);
}
.flex-col-9-3 {
  width: calc(100% / 9.3);
}
.flex-col-9-4 {
  width: calc(100% / 9.4);
}
.flex-col-9-5 {
  width: calc(100% / 9.5);
}
.flex-col-9-6 {
  width: calc(100% / 9.6);
}
.flex-col-9-7 {
  width: calc(100% / 9.7);
}
.flex-col-9-8 {
  width: calc(100% / 9.8);
}
.flex-col-9-9 {
  width: calc(100% / 9.9);
}

.flex-col-10 {
  width: calc(100% / 10);
}
.flex-col-10-1 {
  width: calc(100% / 10.1);
}
.flex-col-10-2 {
  width: calc(100% / 10.2);
}
.flex-col-10-3 {
  width: calc(100% / 10.3);
}
.flex-col-10-4 {
  width: calc(100% / 10.4);
}
.flex-col-10-5 {
  width: calc(100% / 10.5);
}
.flex-col-10-6 {
  width: calc(100% / 10.6);
}
.flex-col-10-7 {
  width: calc(100% / 10.7);
}
.flex-col-10-8 {
  width: calc(100% / 10.8);
}
.flex-col-10-9 {
  width: calc(100% / 10.9);
}

.flex-col-11 {
  width: calc(100% / 11);
}
.flex-col-11-1 {
  width: calc(100% / 11.1);
}
.flex-col-11-2 {
  width: calc(100% / 11.2);
}
.flex-col-11-3 {
  width: calc(100% / 11.3);
}
.flex-col-11-4 {
  width: calc(100% / 11.4);
}
.flex-col-11-5 {
  width: calc(100% / 11.5);
}
.flex-col-11-6 {
  width: calc(100% / 11.6);
}
.flex-col-11-7 {
  width: calc(100% / 11.7);
}
.flex-col-11-8 {
  width: calc(100% / 11.8);
}
.flex-col-11-9 {
  width: calc(100% / 11.9);
}

//// TEMPLATE
//   .flex-col-! {
//     width: calc(100% / !);
//   }
//   .flex-col-!-1 {
//     width: calc(100% / !.1);
//   }
//   .flex-col-!-2 {
//     width: calc(100% / !.2);
//   }
//   .flex-col-!-3 {
//     width: calc(100% / !.3);
//   }
//   .flex-col-!-4 {
//     width: calc(100% / !.4);
//   }
//   .flex-col-!-5 {
//     width: calc(100% / !.5);
//   }
//   .flex-col-!-6 {
//     width: calc(100% / !.6);
//   }
//   .flex-col-!-7 {
//     width: calc(100% / !.7);
//   }
//   .flex-col-!-8 {
//     width: calc(100% / !.8);
//   }
//   .flex-col-!-9 {
//     width: calc(100% / !.9);
//   }
